// import SlackNotify from 'slack-notify';

const VITE_SLACK_WEBHOOK_URL = import.meta.env.VITE_SLACK_WEBHOOK_URL;

export default function useSlackWebhook() {
    const sendMessage = (message: string) => {
        send(JSON.stringify({ message }));
    };
    const sendData = (data: any) => {
        send(JSON.stringify(data));
    };

    const send = (message: any) => {
        // const slack = SlackNotify(VITE_SLACK_WEBHOOK_URL);
        // slack.send(message)
        //     .then(() => {
        //         //console.log('done!');
        //     }).catch((err) => {
        //         //console.error(err);
        //     });
    };

    return {
        sendMessage,
        sendData,
    };
}
