export default {
    index: {
        data: {
            index: '/data',
            sidebar: '/data/sidebar',
        },
        version: '/version',
    },
    feed: '/feed',
    search: '/search',
    auth: {
        token: '',
        login: '/auth/login',
        logout: '/auth/logout',
        user: '/user',
    },
    utils: {
        slack: '/utils/slack',
    },
    dev: {
        action: '/dev/action',
        system: '/dev/system',
    },
    developer: {
        check: '/developer/check',
    },
    user: {
        index: '/user',
        notifications: {
            index: '/user/notifications',
            destroy: '/user/notifications/:id',
            clear: '/user/notifications/clear',
        },
    },
    articles: {
        show: '/articles/:id',
    },
    pages: {
        show: '/pages/:id',
    },
    plugins: {
        show: '/plugins/:id',
    },
    editor: {
        fields: '/:model/editor/fields',
        uuid: '/:model/editor/uuid',
        upload: '/:model/editor/upload/:uuid',
        url: '/:model/editor/url/:uuid',
        search: '/:model/editor/search',
        article: {
            show: '/:model/:id/editor',
            update: '/:model/:id/editor',
            image: '/:model/:id/image',
            video: '/:model/:id/video',
            destroy: '/:model/:id',
            store: '/:model/editor',
            // priority: '/:model/priority',
        },
        item: {
            list: '/:model/:id/editor',
            edit: '/:model/:id/editor',
            show: '/:model/:id/editor',
            update: '/:model/:id/editor',
            updateBlocks: '/:model/:id/editor/blocks',
            image: '/:model/:id/editor/image',
            destroy: '/:model/:id/editor',
            store: '/:model/editor',
            // priority: '/:model/:id/articles/priority',
        },
    },
};
